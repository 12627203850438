import React from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Topbar = () => {
  return (
    <section className='px-[5%] md:px-16 bg-primary  flex justify-between text-md p-2'>
      <section className='flex gap-3 p-1 justify-center items-center text-white'>
        <div className='flex gap-2 justify-center items-center'>
          <FaPhoneAlt className='' />
          <Link href='tel:+97338839020' className='lg:block hidden'>+973 38839020</Link>|
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <FaEnvelope className='' />
          <Link href='mailto:info@blackeagleglobal.com' className='lg:block hidden'>info@blackeagleglobal.com</Link>|
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <FaLocationDot className='' />
          <p className='lg:block hidden'>Mahooz, Kingdom of Bahrain</p>
        </div>
      </section>
      <section className='flex gap-5 justify-center items-center text-white'>
        <Link to='https://www.facebook.com/blackeagleglobal'><FontAwesomeIcon icon={faFacebook} className='cursor-pointer' /></Link>
        <Link to='https://www.instagram.com/blackeagleglobal/'><FontAwesomeIcon icon={faInstagram} className='cursor-pointer' /></Link>
        <Link to='https://www.youtube.com/@BlackEagleGlobal'><FontAwesomeIcon icon={faYoutube} className='cursor-pointer' /></Link>
      </section>
    </section>
  );
}

export default Topbar;
