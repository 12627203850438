import React from 'react'
import banner1 from '../../Images/banner1.jpg'
import banner2 from '../../Images/banner2.jpg'
import mission from '../../Images/mission.png'
import vision from '../../Images/vision2.png'
import corevalue from '../../Images/corevalue.png'
import india from '../../Images/Group_41__2_-removebg-preview.png'
import saudi from '../../Images/saudi_arabia-removebg-preview (1).png'
import bahrain from '../../Images/Group_42-removebg-preview.png'
import dubai from '../../Images/burj khalifa.png'
import { useEffect, useState } from 'react'
import Testimonials from '../Home2/Testimonials'
import whatsetsusapart from '../../Images/whatsetsusapart.jpg'
import backgroundImage from '../../Images/blackeagle.png'
import whatsetsusapart2 from '../../Images/whatsetsusapart2.png'


const AboutUs = () => {
    const words = ["Experience the refreshing breeze of financial liberation alongside us.", "Unlock your financial freedom with us"];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentWord, setCurrentWord] = useState(words[0]);

    // useEffect(() => {
    //     const type = () => {
    //         const typewriterElement = document.getElementById("typewriter");
    //         if (!typewriterElement) {
    //             console.error("Element with ID 'typewriter' not found.");
    //             return;
    //         }
    //         if (isDeleting) {
    //             typewriterElement.textContent = currentWord.substring(0, currentIndex - 1);
    //             setCurrentIndex(prevIndex => prevIndex - 1);
    //             if (currentIndex === 0) {
    //                 setIsDeleting(false);
    //                 setCurrentWordIndex(prevWordIndex => (prevWordIndex + 1) % words.length);
    //                 setCurrentWord(words[currentWordIndex]);
    //             }
    //         } else {
    //             typewriterElement.textContent = currentWord.substring(0, currentIndex + 1);
    //             setCurrentIndex(prevIndex => prevIndex + 1);
    //             if (currentIndex === currentWord.length) {
    //                 setIsDeleting(true);
    //             }
    //         }
    //         setTimeout(type, 10000);
    //     };

    //     type();

    //     // Cleanup function to stop the timeout when the component unmounts
    //     return () => clearTimeout(type);
    // }, [currentIndex]);

    return (

    <div className='font-inter'>
        <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-4xl text-white font-bold">About Us</h1>
          </div>
        </div>
      </div>

        <section className='flex flex-col md:flex-row px-[5%]  p-5 gap-5'>
            <section className='md:w-[70%] flex flex-col gap-5 justify-center'>
                <h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>Welcome to Black Eagle Global Venture</h1>
                <p className='font-light text-sm xs:text-sm md:text-base text-justify lh mt-5 md:mr-10'><i>A premier consulting firm dedicated to empowering businesses to reach their full potential. Based in the vibrant city of Manama, Kingdom of Bahrain, we specialize in providing comprehensive Corporate Finance and Consultancy Services tailored to meet the diverse needs of our clients. Partner with us to navigate the complexities of finance and unlock your business's path to sustained growth and prosperity.</i></p>
            </section>
            <section className='relative flex md:w-[30%] justify-center items-center'>
                <img src={banner1} alt='banner1' className='rounded-lg drop-shadow-2xl'/>
            </section>
        </section>

        <section className='flex flex-col-reverse md:flex-row px-[5%] mt-10 p-5 gap-5 '>
            <section className='relative flex  justify-center items-center'>
                <img src={banner2} alt='banner2' className='rounded-lg'/>
            </section>
            <section className='md:w-[70%] flex flex-col gap-5'>
                <div className='w-full flex md:justify-end justify-start'><h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>What we Stand for?</h1></div>
                <p className='font-light text-sm xs:text-sm md:text-base text-justify md:ml-10 mt-8 lh'><i>At Black Eagle Global Venture, we understand the challenges and complexities facing businesses today. That's why we offer a wide range of services designed to fuel growth, drive transformation, and unlock value across various industries. From strategic financial planning to digital transformation, our team of experts is committed to delivering innovative solutions that propel businesses forward.</i></p>
                </section>  
        </section>

        {/* misison vission */}
        {/* <section className='vm flex flex-col sm:flex-row justify-around p-10 gap-10'>
            <section className='sm:w-[40%] bg-white rounded-lg p-5 flex flex-col gap-5'>
                <h1 className='wesf relative w-fit text-lg md:text-2xl font-bold'>Mission</h1>
                <p className='md:text-lg'><i>"Our mission is to empower businesses with strategic financial solutions and insightful consultancy services."</i></p>
            </section>
            <section className='sm:w-[40%] bg-white rounded-lg p-5 flex flex-col gap-5'>
                <h1 className='wesf relative w-fit text-lg md:text-2xl font-bold'>Vision</h1>
                <p className='md:text-lg'><i>"To be a global leader in corporate finance and consultancy services, driving growth and value creation across diverse sectors and geographies."</i></p>
            </section>
        </section> */}

        <section className='flex flex-col md:flex-row p-5 sm:p-10 gap-8'>
            <section className='bg-slate-300 md:w-[33%] rounded-md p-5 flex flex-col justify-between'>
                <div className='flex justify-center'>
                    <img src={mission} alt='' className='h-20 xs:h-28 md:h-36'/>
                </div>
                <div className='flex flex-col gap-3'>
                    <h1 className='relative w-fit wesf2 mt-5 font-bold text-lg md:text-2xl'>Mission</h1>
                    <p className='md:text-base xs:text-base text-sm'>Our mission is to empower businesses with strategic financial solutions and insightful consultancy services. We are committed to fostering growth, enhancing value, and ensuring financial sustainability for our clients.</p>
                </div>
            </section>
            <section className='bg-slate-300 md:w-[33%] rounded-md p-5 flex flex-col-reverse md:flex-col justify-between'>
                <div className='flex flex-col gap-3'>
                    <h1 className='relative w-fit wesf2 mt-5 font-bold text-lg md:text-2xl'>Vision</h1>
                    <p className='md:text-base xs:text-base text-sm'>To be a global leader in corporate finance and consultancy services, driving growth and value creation across diverse sectors and geographies.</p>
                </div>
                <div className='flex justify-center'>
                    <img src={vision} alt='' className='h-20 xs:h-28 md:h-36'/>
                </div>
            </section>
            <section className='bg-slate-300 md:w-[33%] rounded-md p-5 flex flex-col justify-between'>
                <div className='flex justify-center'>
                    <img src={corevalue} alt='' className='h-20 xs:h-28 md:h-36'/>
                </div>
                <div className='flex flex-col gap-3'>
                    <h1 className='relative w-fit wesf2 mt-5 font-bold text-lg md:text-2xl'>Core Values</h1>
                    <p className='md:text-base xs:text-base text-sm'>Rooted in financial acumen and client-centered ethics, our core values prioritize transparency, strategic foresight, and unwavering dedication to financial empowerment, ensuring tailored solutions and enduring success for our clients.</p>
                </div>
            </section>
        </section>
        
<div>
    <Testimonials />
</div>
        <section className='p-5 md:p-10 w-full flex flex-col gap-5 bg-white rounded-2xl px-10 text-black'>
            <h1 className='text-center text-base xs:text-lg md:text-2xl font-bold'>Our Expertise</h1>
            <p className='text-center mb-5 text-sm xs:text-base md:text-md'>With a proven track record of success and a global reach spanning India, Dubai, Saudi Arabia, and Bahrain, we have established ourselves as a trusted partner for companies seeking strategic guidance and support. Our deep industry knowledge, coupled with our extensive network of partners and associates, allows us to offer unparalleled insights and opportunities to our clients.</p>

            <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-items-center'>

                <section className=''>
                    <img src={india} alt='' className='size-32 xs:size-60 sm:size-52 md:size-60'/>                    
                    <p className='text-center font-bold xs:text-lg md:text-xl'>India</p>
                </section>

                <section>
                    <img src={dubai} alt='' className='size-32 xs:size-60 sm:size-52 md:size-60'/>
                    <p className='text-center font-bold xs:text-lg md:text-xl'>Dubai</p>
                </section>

                <section>
                    <img src={saudi} alt='' className='size-32 xs:size-60 sm:size-52 md:size-60'/>
                    <p className='text-center font-bold xs:text-lg md:text-xl'>Saudi Arabia</p>
                </section>

                <section>
                    <img src={bahrain} alt='' className='size-32 xs:size-60 sm:size-52 md:size-60'/>
                    <p className='text-center font-bold xs:text-lg md:text-xl'>Bahrain</p>
                </section>
            </section>
        </section>

        <div className='bg-[#FD5D13] md:p-12 p-2 '>
            <div className='relative md:px-20 px-4 bg-white text-center rounded-md'>
                <h1 className=' text-center text-base xs:text-lg md:text-2xl font-bold py-10'>Strategic Solutions for Sustainable Success</h1>
                <p className='pb-16 text-justify md:text-center'> What sets us apart is our dedication to excellence, our commitment to delivering tangible results, and our unwavering focus on client satisfaction. Whether you're a startup looking for funding, a growing business in need of strategic direction, or an established company seeking to optimize performance, we're here to help you achieve your goals.

</p>

            </div>
        </div>


        <section className='flex flex-col md:flex-row md:px-16 px-6 md:pb-28 md:pt-16 py-6'>
            <section className='pb-5 md:p-5 flex flex-col gap-5 md:w-[50%] lg:w-[65%]'>
                <h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>Why Choose Us?</h1>
                <p className='font-light text-sm xs:text-sm md:text-base text-justify lh'>Our distinguishing feature lies in our vast reservoir of capital acquisition expertise, spanning both conventional and innovative channels globally. We excel in M&A operations, adeptly navigating growth strategies, and offering tailored analytical and research solutions. With our seasoned management proficiency, we pledge to propel your business towards unparalleled growth and excellence.</p>
                <ul className='list-disc text-sm pl-5 flex flex-col gap-2 font-light  xs:text-sm md:text-base'>
                    <li>Extensive Source of Capital Rasising</li>
                    <li>M&A Capabilities</li>
                    <li>Expertise in Growth Strategies</li>
                    <li>Curated Analytics</li>
                    <li>Research Capabilities</li>
                    <li>Management Expertise</li>
                </ul>
            </section>
            <section className='flex justify-end items-center md:w-[50%] lg:w-[35%]'>
                <img src={whatsetsusapart} alt='' className='block md:hidden rounded-md'/>
                <img src={whatsetsusapart2} alt='' className='hidden md:block h-[550px] lg:h-[450px] rounded-md shadow-2xl'/>
            </section>
        </section>
    </div>
  )
}

export default AboutUs
