import React, { useState } from 'react'
import logo from '../../Images/logo3.png'
import { FaBars } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6';
import '../../App.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [ isOpen, setisOpen ] = useState(false);

    const handleClick = ()=> {
        setisOpen(!isOpen);
    }
  return (
    <section className='px-[5%] lg:px-16 sticky top-0 flex justify-between items-right z-20 bg-white shadow-lg'>
        <Link to='#'><img src={logo} alt='logo' className='h-12 md:h-20 cursor-pointer'/></Link>
        <section className='flex items-center font-semibold'>
        <ul className='gap-5 lg:gap-10 hidden md:flex text-md'>
    <li className='n relative w-fit'><Link to="/">Home</Link></li>
    <li className='n relative w-fit'><Link to="/about">About Us</Link></li>
    <li className='n relative w-fit'><Link to="/services">Services</Link></li>
    <li className='n relative w-fit'><Link to="/blog">Blog</Link></li>
    <li className='n relative w-fit'><Link to="/contact">Contact Us</Link></li>
</ul>

        </section>
        <div className='justify-center items-center hidden md:flex text-sm'>
            <button className='border border-primary2 px-5 py-3 text-white rounded-lg hover:text-primary2 bg-primary2 hover:bg-white hover:scale-110 transition-all shadow-2xl'>Enquire Now</button>
        </div>
        {isOpen ?
            <FaX onClick={handleClick} className='cancel'/>:
            <FaBars className='block md:hidden text-xl bars' onClick={handleClick}/>
        }
        {isOpen ?
            <section className='absolute top-[40px] right-0 h-[100vh] w-[70%] sm:w-[40%] text-primary bg-white z-10 ls'>
               <ul className='flex flex-col gap-3 py-4'>
    <li className='hover:text-white hover:bg-primary px-4'><Link to="/">Home</Link></li>
    <li className='hover:text-white hover:bg-primary px-4'><Link to="/about">About Us</Link></li>
    <li className='hover:text-white hover:bg-primary px-4'><Link to="/services">Services</Link></li>
    <li className='hover:text-white hover:bg-primary px-4'><Link to="/blog">Blog</Link></li>
    <li className='hover:text-white hover:bg-primary px-4'><Link to="/contact">Contact Us</Link></li>
</ul>

            </section> :null
        }
    </section>
  )
}

export default Navbar
