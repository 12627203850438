import React, { useEffect, useState } from 'react'
import banner from '../../Images/banner.png'
import Slider from 'react-slick';
import banner1 from '../../Images/banner.png'
import banner2 from '../../Images/bannerbg3.jpg';
import banner3 from '../../Images/black1.png';

const Banner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false
  };

  const [ active, setActive ] = useState('');

  const data = [["Drive Your Growth", "With Innovative and", "Diverse Capital Solutions"],
    ["Tailored Strategies, Tangible Growth:", "Experience Our Expertise", "To Elevate Our Growth"], 
    ["Innovative Partnerships:", "Join us to Drive", "Sustainable Growth"]]

  useEffect(()=> {
    const time = setInterval(()=> {
        setActive((prev)=> (prev+data.length+1)%data.length)
    }, 2000);

    // console.log(active)
    return ()=> clearInterval(time)
  }, [data])

  return (
    <section className="banner-section w-full h-full relative">
      <Slider {...settings} className='w-full h-full'>
        <div className="relative w-full h-[300px] md:h-[500px]">
          <img src={banner1} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2  flex flex-col justify-center items-start px-6 md:px-20 text-white">
            <h2 className="text-lg sm:text-2xl lg:text-5xl font-inter font-semibold text-left md:pt-10">
            Drive your growth<br/> with innovative and <br/>diverse capital solutions
            </h2>
            <a href="/ContactUs" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Learn More
              </button>
            </a>
          </div>
        </div>
        <div className="relative w-full h-[300px] md:h-[500px]">
          <img src={banner2} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2  flex flex-col justify-center items-start px-6 md:px-20 text-white">
            <h2 className="text-lg sm:text-2xl lg:text-5xl font-inter font-semibold text-left md:pt-10">
            Tailored Strategies,Tangible<br/> Growth: Experience our expertise <br/> to elevate your business.
            </h2>
            <a href="/ContactUs" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Learn More
              </button>
            </a>
          </div>
        </div>
        <div className="relative w-full h-[300px] md:h-[500px]">
          <img src={banner3} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2  flex flex-col justify-center items-start px-6 md:px-20 text-white">
            <h2 className="text-lg sm:text-2xl lg:text-5xl font-inter font-semibold text-left md:pt-10">
              Innovative Partnerships:<br/> Join us to drive <br/>sustainable growth.
            </h2>
            <a href="/ContactUs" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Learn More
              </button>
            </a>
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default Banner
