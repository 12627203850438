import React from 'react'
import aboutus from '../../Images/about.png'
import { RiShieldUserFill } from 'react-icons/ri'
import { FaPeopleGroup } from 'react-icons/fa6'


const Cards = () => {
  return (
    <div className='py-5'>
      {/* <section className='bg-gradient-to-r from-[#1247B4] via-[#1247B4] to-white w-[90%] ml-[10%] rounded-xl flex justify-between overflow-hidden relative'>
        <RiShieldUserFill className='absolute text-8xl bg-white rounded-full p-3' />
        <section className='flex flex-col'>
            <div className='pl-20'>
                <h1>Capital Advisory</h1>
                <p>Empowering your business growth strategies with diverse and global capital solutions.</p>
            </div>
        </section>
        <section className='flex relative flex-col'>
            <RiShieldUserFill className='absolute' />
            <div className='pl-20'>
                <h1>Consultant Services</h1>
                <p>Enabling you to dream big and accelerate your growth and valuation with our strategic business solutions.</p>
            </div>
        </section>
      </section> */}
      {/* <div>
        <div className='relative md:mt-0 mt-10'>
          <div className='flex flex-col w-full items-center gap-10'>
            <div className='md:p-0 large:hidden static p-5'>
              <div className='h-full flex md:flex-row flex-col md:justify-start text-white justify-center relative gap-3 md:w-fit items-center ' style={{ background: 'linear-gradient(to right, #1247B4,#1247B4,#1247B4, #FFFFFF)' }}>
                <FaPeopleGroup className='bg-white md:-ml-12 md:-mt-0 -mt-10 shadow-2xl text-orange-500 drop-shadow-2xl size-16 p-3 rounded-full' />
                <div className='flex text-wrap text-center md:text-start flex-col p-2'> 
                  <p className='md:text-base text-sm font-bold'>Empowering your</p>
                  <p className='md:text-sm text-xs'>Empowering your business growth strategies with diverse and global capital solutions.</p>
                </div>
              </div>
            </div>
            <div className='md:p-0 large:hidden static p-5'>
              <div className='h-full flex md:flex-row flex-col md:justify-start text-white justify-center relative gap-3 md:w-fit items-center ' style={{ background: 'linear-gradient(to right, #1247B4,#1247B4,#1247B4, #FFFFFF)' }}>
                <FaPeopleGroup className='bg-white md:-ml-12 md:-mt-0 -mt-10 shadow-2xl text-orange-500 drop-shadow-2xl size-16 p-3 rounded-full' />
                <div className='flex text-wrap text-center md:text-start flex-col p-2'> 
                  <p className='md:text-base text-sm font-bold'>Empowering your</p>
                  <p className='md:text-sm text-xs'>Empowering your business growth strategies with diverse and global capital solutions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='large:flex hidden mt-10  justify-end items-end'>
          <div className='bg-gradient-to-r from-[#1247B4] via-[#1247B4] to-white text-white gap-20 flex w-[85%] h-20'>
            <div className='h-full flex relative gap-3 items-center -ml-12'>
              <FaPeopleGroup className='bg-white shadow-2xl text-orange-500 drop-shadow-2xl size-24 p-3 rounded-full' />
              <div className='flex text-wrap flex-col p-2'> 
                <p>Empowering your</p>
                <p className='text-sm '>Empowering your business growth strategies<br></br> with diverse and global capital solutions.</p>
              </div>
            </div>
            <div className='h-full flex gap-3 items-center -ml-5'>
              <FaPeopleGroup className='bg-white drop-shadow-2xl text-orange-500 shadow-2xl p-3 size-24 rounded-full ' />
              <div className='flex text-wrap flex-col'>
                <p>Empowering your</p>
                <p className='text-sm'>Empowering your business growth strategies <br></br> with diverse and global capital solutions.</p>
              </div>
            </div>
          </div>   
        </div>
      </div> */}

<div className='w-full md:px-20 lg:px-0'>
        <div className='relative md:mt-0 '>
          <div className='flex flex-col w-full items-center gap-5 mt-5'>
            <div className='md:p-0  large:hidden static p-5'>
              <div className='h-full flex md:flex-row flex-col md:justify-start text-white justify-center relative gap-3 md:w-fit items-center ' style={{ background: 'linear-gradient(to right, #1247B4,#1247B4,#1247B4, #1247B4)' }}>
              <RiShieldUserFill className='bg-white md:-ml-8 md:-mt-0 -mt-10 shadow-2xl text-orange-500 drop-shadow-2xl size-16 p-3 rounded-full' />
                <div className='flex  text-wrap text-center md:text-start flex-col p-2'> 
                  <p className='md:text-base text-sm font-bold'>CAPITAL ADVISORY</p>
                  <p className='md:text-sm text-xs'>Empowering your business growth strategies with diverse and global capital solutions</p>
                </div>
              </div>
            </div>
            <div className='md:p-0 large:hidden static p-5'>
              <div className='h-full flex md:flex-row flex-col md:justify-start text-white justify-center relative gap-3 md:w-fit items-center ' style={{ background: 'linear-gradient(to right, #1247B4,#1247B4,#1247B4, #1247B4)' }}>
                <FaPeopleGroup className='bg-white md:-ml-8 md:-mt-0 -mt-10 shadow-2xl text-orange-500 drop-shadow-2xl size-16 p-3 rounded-full' />
                <div className='flex text-wrap text-center md:text-start flex-col p-2'> 
                  <p className='md:text-base text-sm font-bold'>CONSULTANCY SERVICES</p>
                  <p className='md:text-sm text-xs'>Enabling you to dream big and accelerate your growth and valuation with our strategic business solutions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='large:flex hidden py-5 -ml-12 justify-end items-end'>
          <div className='bg-gradient-to-r from-[#1247B4] via-[#1247B4] to-blue-100 text-white gap-20 flex w-[85%] h-20'>
            <div className='h-full flex  relative gap-3 items-center -ml-16'>
            <RiShieldUserFill className='bg-white shadow-2xl text-orange-500 drop-shadow-2xl size-24 p-3 rounded-full' />
              <div className='flex text-wrap flex-col p-2'> 
                <p className='text-md font-bold'>CAPITAL ADVISORY
</p>
                <p className='text-sm '>Empowering your business growth strategies with diverse <br/>and global capital solutions</p>
              </div>
            </div>
            <div className='h-full flex gap-3 items-center -ml-10'>
              <FaPeopleGroup className='bg-white drop-shadow-2xl text-orange-500 shadow-2xl p-3 size-24 rounded-full ' />
              <div className='flex text-wrap flex-col p-2'>
              <p className='text-md font-bold'>CONSULTANCY SERVICES</p>
                <p className='text-sm'>Enabling you to dream big and accelerate<br/> your growth and valuation with our strategic business solutions</p>
              </div>
            </div>
          </div>   
        </div>
      </div>

      <section className='pt-10 px-[5%] md:px-[8%] flex flex-col lg:flex-row gap-10 lg:justify-between justify-center items-center'>
        <img src={aboutus} alt='' className='w-[90%] lg:w-[50%]' />
        <section className='flex flex-col gap-5 lg:justify-between justify-center lg:items-baseline items-center'>
            <h1 className='font-bold text-xl md:text-3xl md:text-left text-center'>How We Become Best Among Others?</h1>
            <p className='text-primary'>Becoming The Pinnacle Among Peers</p>
            <p className='text-justify'>Welcome to Black Eagle Global Ventures, a premier consulting firm dedicated to empowering businesses to reach their full potential. Based in the vibrant city of Manama, Kingdom of Bahrain, we specialize in providing comprehensive Corporate Finance and Consultancy Services tailored to meet the diverse needs of our clients.</p>
            <button className='px-4 py-2 bg-primary rounded-lg text-white hover:scale-110 transform transition-all'>Know More</button>
        </section>
      </section>
    </div>
  )
}

export default Cards
