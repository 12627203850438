import React from 'react';
import { Link } from 'react-router-dom';

const Bottombar = () => {
  return (
    <div className='w-full bg-primary'>
      <p className='font-inter text-md text-center p-1 text-white'>
        Powered by <Link href='https://whyglobalservices.com/' className='hover:text-black'>WHY Global Services</Link>
      </p>
    </div>
  );
}

export default Bottombar;
