import React, { useEffect } from 'react';
import ABOUT from '../../Images/black6.png';
import backgroundImage from'../../Images/blackeagle.png';
import { FaLocationDot,FaPhone } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import { MdEmail } from "react-icons/md";


const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])
  return (
    <section className="flex flex-wrap items-center">
      <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-4xl text-white font-bold">Contact Us</h1>
          </div>
        </div>
      </div>
      {/* Left column with image */}
      <div className=" w-full p-4 md:p-0 md:pl-10 md:pt-16 md:w-1/2">
      <img src={ABOUT} alt="Explore All" className="" />
      </div>

      {/* Right column with form */}
      <div className="w-full h-fit p-4 md:p-0 md:pt-16 md:pr-14 md:w-1/2">
        <form className="bg-gray-100 md:p-10 p-4 rounded-lg">
          <h2 className="text-xl font-bold mb-6">GET FREE CONSULTATION</h2>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2"></label>
            <input type="text" id="name" name="name" placeholder="Name" className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2"></label>
            <input type="email" id="email" name="email" placeholder="Email" className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="mb-4">
            <label htmlFor="number" className="block text-gray-700 font-bold mb-2"></label>
            <input type="number" id="number" name="number" placeholder="Phone" className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-700 font-bold mb-3"></label>
            <textarea id="message" name="message" placeholder="Message" rows="4" className="w-full p-2 border border-gray-300 rounded-lg"></textarea>
          </div>
          <button type="submit" className="bg-[#fd5d14] text-white font-bold py-3 px-6">Get a Quote</button>
        </form>
      </div>

      {/* <div class="mx-auto">
  <div class="flex justify-around flex-wrap md:flex-nowrap md:p-10 p-5 gap-4">
    <div class="shadow-xl flex-grow w-full md:w-72 p-6">
      <div class="bg-[#fd5d14] w-8 h-8 p-1"><FaLocationDot size={25}/></div>
      <h1 class="py-3 font-semibold">OUR LOCATION</h1>
      <div class="text-gray-700">
        <p>Mahooz Avenue<br/>Unit 401 / 0334, Road 0337<br/>Mahooz, Kingdom of Bahrain.</p>
      </div>
    </div>
    <div class="shadow-xl flex-grow w-full md:w-72 p-6">
      <div class="bg-[#000] w-8 h-8 p-1"><IoTime size={25} class="text-white"/></div>
      <h1 class="py-3 font-semibold">WORKING TIME</h1>
      <div class="text-gray-700">
        <p>Mon-Fri : 7AM-5PM</p>
        <p>Saturday : 9AM-3PM</p>
        <p>Sunday(Holiday)</p>
      </div>
    </div>
    <div class="shadow-xl flex-grow w-full md:w-72 p-6">
      <div class="bg-[#fd5d14] w-8 h-8 p-1 mb-2"><FaPhone size={25}/></div>
      <h1 class="py-3 font-semibold">OUR NUMBERS</h1>
      <div class="text-gray-700">
        <p><a href="tel:+966500064365">+966 500064365</a></p>
        <p><a href="tel:+971585964365">+971 585964365</a></p>
        <p><a href="tel:+966545025171">+966 545025171</a></p>
      </div>
    </div>
    <div class="shadow-xl flex-grow w-full md:w-72 p-6">
      <div class="bg-[#000] w-8 h-8 p-1"><MdEmail size={25} class="text-white"/></div>
      <h1 class="py-3 font-semibold">EMAIL ADDRESS</h1>
      <div class="text-gray-700">
        <p><a href="mailto:info@blackeagleglobal.com">info@blackeagleglobal.com</a></p>
      </div>
    </div>
  </div>
</div> */}






    </section>
  );
};

export default Contact;
