import React from 'react';
import Topbar from './Components/Home2/Topbar';
import Navbar from './Components/Home2/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home2/Home';
import AboutUs from './Components/About/AboutUs';
import Footer from './Components/Home2/Footer';
import Contact  from'./Components/Contact Us/Contact';
import Bottombar from './Components/Home2/Bottombar';
// import Blog from './Components/Blog/Blog';




function App() {
  return (
    <div className='font-inter'>
      <Topbar />
      <Navbar />
      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/about' Component={AboutUs}/>
        {/* <Route path='/blog' Component={Blog} /> */}
        <Route path='/contact' Component={Contact} />
       
      </Routes>
      <Footer />
      <Bottombar />
    </div>
  );
}

export default App;
