import React from 'react'
import services from '../../Images/services.png'
import { FaFacebook } from 'react-icons/fa6'
import ourservicesbg from '../../Images/Group 35.png'
import investmentbanking from '../../Images/investmentbanking.png'
import businessfinance from '../../Images/businessfinance.png'
import realestatefinance from '../../Images/realestatefinance.png'
import projectfinance from '../../Images/projectfinance.png'
import managementconsultancy from '../../Images/managementconsultancy.png'
import financialanalytics from '../../Images/financialanalytics.png'
import accountingservices from '../../Images/accountingservices.png'

const OurServices = () => (
    <div className='w-full ourservicesbg text-white h-full bg-no-repeat bg-cover  lg:p-8 p-5'>
        <div className='w-full flex flex-col lg:h-[500px] h-fit gap-6'>
            <div className='flex flex-col justify-center gap-2 h-full items-center'>
                <p className='md:text-3xl text-xl font-semibold '>Our Services</p>
                <p className=' md:w-[50%] w-full text-sm large:text-base text-justify sm:text-center'>Explore our comprehensive suite of tailored services designed to drive your business forward with precision and expertise.</p>
           </div>
           <div className='w-full grid  lg:grid-cols-2 gap-10 grid-cols-1 justify-items-center h-full px-0  lg:px-36'>
            <div className='bg-white border-2 rounded-md lg:w-80 h-96 flex flex-col justify-between sm:w-[50%] w-full  '>
            <div className='flex mx-auto flex-row w-full h-12 mt-3 bg-primary2 justify-center items-center text-center'><p className='w-full text-center'>Capital Advisory</p></div>
                <div className='flex flex-col justify-around h-full text-black'>
                    
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute p-1  drop-shadow-2xl large:-left-4 -left-3'><img src={investmentbanking} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Investment Banking</p>
                    </div>
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute  drop-shadow-2xl p-1 large:-left-4 -left-3'><img src={businessfinance} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Business Finance</p>
                    </div>
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute  drop-shadow-2xl p-1 large:-left-4 -left-3'><img src={realestatefinance} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Real Estate Finance</p>
                    </div>
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute  drop-shadow-2xl p-1 large:-left-4 -left-3'><img src={projectfinance} alt='' className='large:size-10 size-8 text-blue-500'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Project Finance</p>
                    </div>
                   
                </div>
                <div className='bg-orange-400 px-3 py-2 mx-auto rounded-md w-fit mb-3'>Learn More</div>

            </div>
            <div className='bg-white border-2 rounded-md  lg:w-80 h-96 flex flex-col justify-around sm:w-[50%] w-full'>
            <div className='flex mx-auto flex-row w-full h-12 mt-3 bg-primary2 justify-center items-center '><p>Consultancy Services</p></div>
                <div className='flex flex-col justify-around h-full text-black'>
                 
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute p-1  drop-shadow-2xl large:-left-4 -left-3'><img src={managementconsultancy} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Management Consultancy</p>
                    </div>
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute  drop-shadow-2xl p-1 large:-left-4 -left-3'><img src={financialanalytics} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Financial Analytics & Research</p>
                    </div>
                    <div className='flex relative items-center mx-auto flex-row w-[90%] large:w-[80%] h-8 large:h-10 bg-gradient-to-r from-slate-300 to-transparent'>
                        <div className='bg-white rounded-full absolute  drop-shadow-2xl p-1 large:-left-4 -left-3'><img src={accountingservices} alt='' className='large:size-10 size-8'/></div>
                        <p className='text-center text-xss xs:text-xs w-full'>Accounting Services</p>
                    </div>
                  
                   
                </div>
                <div className='bg-orange-400 px-3 py-2 mx-auto rounded-md w-fit mb-3'>Learn More</div>

            </div>
           </div>
        </div>
    </div>
)

export default OurServices
