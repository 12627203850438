import React from 'react'
import Banner from './Banner'
import Cards from './Cards'
import OurServices from './OurServices'
import AssociateCompanies from './AssociateCompanies'
import IndustryCoverage from './IndustryCoverage'
import Discover from './Discover'
import Footer from './Footer'
import Bottombar from './Bottombar'
import Testimonials from './Testimonials'



const Home = () => {
  return (
    <div>
      <Banner/>
      {/* <Banner /> */}
      <Cards />
      {/* <Consultation /> */}
      <OurServices />
      <AssociateCompanies />
      <IndustryCoverage />
      <Discover />
      <Testimonials />
      
       
    </div>
  )
}

export default Home
